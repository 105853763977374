<template>
  <div>
    <!-- Filter card -->
    <vx-card>
      <div class="flex flex-wrap lg:flex-no-wrap">
        <div class="vx-row flex-grow">
          <div class="vx-col md:w-1/3 sm:w-1/2 w-full">
            <label class="text-sm text-san-juan font-bold"
              >Fecha de inicio</label
            >
            <datepicker
              :language="langEs"
              :disabledDates="disabledDatesFrom"
              name="start-date"
              v-model="startDate"
            ></datepicker>
          </div>
          <div class="vx-col md:w-1/3 sm:w-1/2 w-full">
            <label class="text-sm text-san-juan font-bold">Fecha de fin</label>
            <datepicker
              :language="langEs"
              :disabledDates="disabledDatesTo"
              name="end-date"
              v-model="endDate"
            ></datepicker>
          </div>
          <div class="vx-col md:w-1/3 sm:w-1/2 w-full">
            <vs-select
              label="Contratistas"
              class="w-full"
              v-model="deliverymanSelected"
              placeholder="Seleccione un contratista"
              autocomplete
            >
              <vs-select-item
                :key="index"
                :value="item.user.id"
                :text="`${item.code ? item.code : 'N/A'} - ${
                  item.user.first_name + ' ' + item.user.last_name
                }`"
                v-for="(item, index) in deliverymen"
              />
            </vs-select>
          </div>
        </div>
        <div class="mt-6 lg:ml-3">
          <vs-button @click="resetFilter" class="ml-1 sm:ml-3" type="border">
            Limpiar
          </vs-button>
        </div>
      </div>
    </vx-card>
    <div
      class="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-1 md:gap-4 mt-12"
    >
      <statistics-card-line
        hideChart
        class="mb-base sm:mb-1"
        :icon="getTypeIcon(type)"
        icon-right
        :statistic="type.total | currencyFormat"
        :statisticTitle="'Total ' + $options.filters.typeTransaction(type.type)"
        :color="getTypeColor(type)"
        :key="index"
        v-for="(type, index) in totalTypes"
      />
      <statistics-card-line
        hideChart
        class="mb-base"
        icon-right
        :icon="getTypeIcon({ type: 'INCOME' })"
        :statistic="totalTotalTypes | currencyFormat"
        :statisticTitle="'Total'"
        :color="getTypeColor('INCOME')"
      />
    </div>
    <div class="vx-card p-6 mt-12">
      <div class="flex flex-wrap md:flex-no-wrap justify-between items-center">
        <vs-input
          class="mb-4 w-full md:w-auto md:flex-grow md:mb-0 mr-4"
          v-model="search"
          placeholder="Buscar..."
        />
        <vs-button
          type="border"
          class="mb-4 md:mb-0"
          @click="exportDeliverymanBalance"
          >Exportar</vs-button
        >
      </div>

      <app-loading :loading="loading"></app-loading>
      <!-- AgGrid Table -->
      <ag-grid-vue
        ref="agGridTable"
        :gridOptions="agGridState.gridOptions"
        class="ag-theme-material w-100 my-4 ag-grid-table"
        :columnDefs="columnDefs"
        :defaultColDef="agGridState.defaultColDef"
        :rowData="transactions"
        rowSelection="multiple"
        colResizeDefault="shift"
        :animateRows="true"
        :floatingFilter="false"
        :pagination="true"
        v-if="!loading"
        @grid-ready="onGridReady"
        :paginationPageSize="paginationPageSize"
        :suppressPaginationPanel="true"
        :enableRtl="$vs.rtl"
      >
      </ag-grid-vue>

      <vs-pagination
        :total="totalPages"
        :max="agGridState.maxPageNumbers"
        v-model="currentPage"
      />
    </div>
  </div>
</template>

<script>
import { es } from 'vuejs-datepicker/src/locale';
import Datepicker from 'vuejs-datepicker';
import StatisticsCardLine from '@/components/statistics-cards/StatisticsCardLine.vue';
import moment from 'moment';
import AppLoading from '@/components/AppLoading';
import {
  computed,
  defineComponent,
  reactive,
  ref,
  toRefs,
} from '@vue/composition-api';
import { useAgGrid } from '@/composable/useAgGrid';
import { DELIVERYMEN_BALANCE, DELIVERYMEN } from '@/graphql/queries';
import { useQuery, useResult } from '@vue/apollo-composable';
import CellRendererAmount from '@/components/cell-renderer/CellRendererAmount.vue';

export default defineComponent({
  setup(_, { root }) {
    const {
      state: agGridState,
      onGridReady,
      paginationPageSize,
      updateSearchQuery,
    } = useAgGrid({ root });

    const state = reactive({
      langEs: es,
      startDate: new Date(),
      endDate: new Date(),
      deliverymanSelected: null,
      saldos: [],
      // AgGrid
      columnDefs: [
        {
          headerName: '#',
          field: 'id',
          width: 125,
          filter: true,
        },
        {
          headerName: 'Código Aliado',
          field: 'deliveryman.deliveryman.code',
          width: 125,
          filter: true,
        },
        {
          headerName: 'Nombre',
          field: 'deliveryman.first_name',
          width: 125,
          filter: true,
        },
        {
          headerName: 'Valor',
          field: 'amount',
          cellRendererFramework: 'CellRendererAmount',
          width: 125,
          filter: true,
        },
        {
          headerName: 'Descripción',
          field: 'description',
          width: 125,
          filter: true,
        },
        {
          headerName: 'Fecha',
          field: 'created_at',
          width: 125,
          filter: true,
        },
        {
          headerName: 'Servicio',
          field: 'service.id',
          width: 125,
          filter: true,
        },
      ],
    });

    const currentPage = ref(1);
    const sDate = computed(() => moment(state.startDate).format('YYYY-MM-DD'));
    const eDate = computed(() => moment(state.endDate).format('YYYY-MM-DD'));
    const deliverymanSelected = computed(() => state.deliverymanSelected);
    const search = ref('');
    const { result: transactionsResult, loading } = useQuery(
      DELIVERYMEN_BALANCE,
      {
        page: currentPage,
        first: 10,
        startDate: sDate,
        endDate: eDate,
        user_deliveryman_id: deliverymanSelected,
      }
    );
    const { result: deliverymenResult } = useQuery(DELIVERYMEN);
    const deliverymen = useResult(
      deliverymenResult,
      [],
      (data) => data.deliverymen
    );
    const transactions = useResult(
      transactionsResult,
      [],
      (data) => data.deliverymenBalance.transactions.data
    );

    const totalTypes = useResult(
      transactionsResult,
      [],
      (data) => data.deliverymenBalance.totalTypes
    );
    const totalTotalTypes = useResult(transactionsResult, [], (data) =>
      data.deliverymenBalance.totalTypes
        .map((t) => t.total)
        .reduce((accumulator, currentValue) => accumulator + currentValue)
    );

    const exportDeliverymanBalance = () => {
      const fileName = 'balance.xlsx';
      root.$http
        .post(
          'api/deliverymen-balance/export',
          {
            startDate: sDate.value,
            endDate: eDate.value,
            deliveryman_id: deliverymanSelected.value,
          },
          { responseType: 'blob' }
        )
        .then((response) => {
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', fileName); //or any other extension
          document.body.appendChild(link);
          link.click();
        });
    };

    const getTypeIcon = (type) => {
      switch (type.type) {
        case 'INCOME':
          return 'ArrowUpIcon';
        case 'EXPEND':
          return 'ArrowDownIcon';
        case 'RECHARGE':
          return 'DownloadIcon';
        case 'DISCHARGE':
          return 'ArrowDownIcon';
        case 'TRANSFER':
          return 'ChevronsRightIcon';
        case 'WITHDRAW':
          return 'ArrowDownIcon';
      }
    };

    const getTypeColor = (type) => {
      switch (type.type) {
        case 'RECHARGE':
          return 'success';
        case 'DISCHARGE':
          return 'danger';
      }
    };

    const paginatorInfo = useResult(transactionsResult, [], (data) => ({
      total: data.deliverymenBalance.transactions.paginatorInfo.total,
      perPage: 10,
    }));

    const totalPages = computed(() =>
      Math.ceil(paginatorInfo.value.total / paginatorInfo.value.perPage)
    );

    const disabledDatesTo = computed(() => {
      return { to: new Date(state.startDate) };
    });
    const disabledDatesFrom = computed(() => {
      return { from: new Date(state.endDate) };
    });

    const resetFilter = () => {
      state.deliverymanSelected = '';
    };

    return {
      agGridState,
      paginationPageSize,
      totalPages,
      updateSearchQuery,
      currentPage,
      disabledDatesTo,
      disabledDatesFrom,
      paginatorInfo,
      transactions,
      loading,
      search,
      resetFilter,
      deliverymen,
      totalTypes,
      getTypeColor,
      onGridReady,
      totalTotalTypes,
      exportDeliverymanBalance,
      getTypeIcon,
      ...toRefs(state),
    };
  },
  components: {
    Datepicker,
    CellRendererAmount,
    StatisticsCardLine,
    AppLoading,
  },
});
</script>
